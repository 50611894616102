import LogoImg from 'shared/assets/icons/Logo.webp'
import LogoDarkImg from 'shared/assets/icons/LogoDark.webp'
import LogoCenterImg from 'shared/assets/icons/LogoCenter.webp'
import styles from './Logo.module.scss'

interface LogoProps {
  type?: 'default' | 'dark' | 'center'
}

export function Logo(props: LogoProps) {
  const { type = 'default'} = props

  const logo = type === "default" ? LogoImg : type === "dark" ? LogoDarkImg : LogoCenterImg

  return (
    <a href="/" className={styles.logo}>
      <img src={logo} alt="logo" />
    </a>
  )
}
