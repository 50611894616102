import cl from 'classnames'
import TgIcon from 'shared/assets/images/tg/TgIcon.webp'
import { Button } from 'shared/ui/button'
import { Accordion } from 'shared/ui/Accordion'
import { questionsContent } from '../model/consts/questionsContent'

import cls from './Questions.module.scss'

export const Questions = () => {
  return (
    <section className={cl('flex__col_center', cls.page)} id="questions">
      <div className={cl(cls.page__container, 'container__desktop')}>
        <div className={cls.header}>
          <h3 className={cls.header__title}>Telegram-канал</h3>
        </div>

        <div className={cls.tg}>
          <img src={TgIcon} alt="TgIcon" />
          <div className={cls.tg_desc}>
            <p>
              Подписывайтесь на наш Telegram-канал и получайте актуальную
              информацию
            </p>
            <Button isLink isBlank href="#tg" className={cls.btn_accept}>
              Перейти в Telegram-канал
            </Button>
          </div>
        </div>
      </div>

      <div className={cl(cls.page__container, 'container__desktop')}>
        <div className={cls.header}>
          <h3 className={cls.header__title}>Частые вопросы</h3>
        </div>
        <div className={cls.content}>
          <Accordion content={questionsContent} className={cls.accordion} />
        </div>
      </div>
    </section>
  )
}
