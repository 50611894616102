import cl from 'classnames'
import { Button } from 'shared/ui/button'
import { CoinGif, Tabs } from 'shared/ui'
import { FirstProgram } from './FirstProgram/FirstProgram'
import { SecondProgram } from './SecondProgram/SecondProgram'

import cls from './Programs.module.scss'

export const Programs = () => {
  const tabs = [
    { title: '17 мая', content: <FirstProgram /> },
    { title: '18 мая', content: <SecondProgram /> },
  ]

  return (
    <section className={cls.section} id="programs">
      <div className={cls.content}>
        <CoinGif className={cls.coin} />

        <div className={cl('container__desktop', cls.container)}>
          <h2 className={cls.title}>Программа</h2>

          <Tabs tabs={tabs} />

          <Button className={cls.btn_accept} isLink>
            Принять участие
          </Button>
        </div>
      </div>
    </section>
  )
}
