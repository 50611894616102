import { AccordionContent } from 'shared/ui/Accordion'

export const questionsContent: AccordionContent[] = [
  {
    id: 1,
    title: 'Что такое «Толк старт»?',
    desc1:
      '«Толк старт» — это двухдневный вебинар, который состоит из предзаписанного контента интенсива «Тинькофф Толк» и онлайн-практикумов с участием спикеров Тинькофф',
  },
  {
    id: 2,
    title: 'Когда и где будет проходить вебинар?',
    desc1:
      '17—18 апреля в 19:00 по московскому времени на интерактивной образовательной платформе',
  },
  {
    id: 3,
    title: 'О чем вебинар?',
    desc1:
      'О деньгах и инвестициях. Вы научитесь эффективно управлять своими финансами и разберетесь в азах фондового рынка',
  },
  {
    id: 4,
    title: 'В каком формате пройдет вебинар?',
    desc1:
      'Вебинар пройдет в онлайн-формате. Принять участие можно из любой точки мира',
  },
  {
    id: 5,
    title: 'Не пришло подтверждение участия, что делать?',
    desc1: (
      <>
        Проверьте адрес почты, который вы указали при регистрации, и папку
        «Спам». Если подтверждение не приходит в течение 12 часов, напишите нам
        в{' '}
        <a href="#tg" target="_blank">
          Telegram
        </a>
      </>
    ),
  },
  {
    id: 6,
    title: 'В чем преимущество платного тарифа?',
    desc1:
      'Пользователи платного тарифа получат доступ не только к лекционной, но и практической части вебинара, а также возможность задать в прямом эфире свой вопрос спикеру. Кроме того, пользователям платного тарифа будут доступны дополнительные материалы, закрытый Telegram-чат и сертификат о прохождении вебинара «Толк старт»',
  },
]
