import React, { useState } from 'react'
import LogoCenterImg from 'shared/assets/icons/LogoCenter.webp'
import TalkMobileImg from 'shared/assets/images/promo/TalkMobile.webp'
import BurgerButtonIcon from 'shared/assets/icons/BurgerButton.webp'
import CloseIcon from 'shared/assets/icons/BurgerClose.webp'
import { Button } from 'shared/ui/button'

import cls from './BurgerMenu.module.scss'

export const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      {!isOpen && (
        <img
          src={BurgerButtonIcon}
          alt="BurgerButtonIcon"
          className={cls.button}
          onClick={toggleMenu}
        />
      )}

      {isOpen && (
        <img
          src={CloseIcon}
          alt="close"
          className={cls.button_close}
          onClick={toggleMenu}
        />
      )}

      <div className={`${cls['slide-from-top']} ${isOpen ? cls['open'] : ''}`}>
        <div className={cls['menu-content']}>
          <div className={cls.content_container}>
            <div className={cls.content_logo}>
              <img src={LogoCenterImg} alt="LogoCenterImg" />
            </div>

            <div className={cls.content_talk}>
              <img src={TalkMobileImg} alt="TalkMobileImg" />
            </div>

            <div className={cls.content_links}>
              <a href="#programs" onClick={toggleMenu}>
                Программа
              </a>
              <a href="#speakers" onClick={toggleMenu}>
                Спикеры
              </a>
              <a href="#questions" onClick={toggleMenu}>
                FAQ
              </a>
            </div>

            <div className={cls.desc}>
              <Button isLink className={cls.btn_accept} onClick={toggleMenu}>
                Принять участие
              </Button>
              <a
                href="#add_to_calendar"
                target="_blank"
                className={cls.btn_calendar}
              >
                Добавить в календарь
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
