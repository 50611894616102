import FeaturesIcon1 from 'shared/assets/images/features/FeaturesIcon1.webp'
import FeaturesIcon2 from 'shared/assets/images/features/FeaturesIcon2.webp'
import FeaturesIcon3 from 'shared/assets/images/features/FeaturesIcon3.webp'
import FeaturesIcon4 from 'shared/assets/images/features/FeaturesIcon4.webp'
import FeaturesIcon5 from 'shared/assets/images/features/FeaturesIcon5.webp'
import FeaturesIcon6 from 'shared/assets/images/features/FeaturesIcon6.webp'

interface FeaturesCardItem {
  id: number
  img: string
  text: string
}

export const featuresCardItems: FeaturesCardItem[] = [
  {
    id: 1,
    img: FeaturesIcon1,
    text: 'Научиться управлять своими деньгами',
  },
  {
    id: 2,
    img: FeaturesIcon2,
    text: 'Узнать, куда вложить свои средства сегодня',
  },
  {
    id: 3,
    img: FeaturesIcon3,
    text: 'Регулярно получать пассивный доход',
  },
  {
    id: 4,
    img: FeaturesIcon4,
    text: 'Разобраться, как выбрать акции, фонды и другие ценные бумаги в портфель',
  },
  {
    id: 5,
    img: FeaturesIcon5,
    text: 'Понять, как сформировать подушку безопасности',
  },
  {
    id: 6,
    img: FeaturesIcon6,
    text: 'Накопить намечту и крупные покупки',
  },
]
