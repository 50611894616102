import cl from 'classnames'
import cls from './CoinGif.module.scss'

interface CoinGifProps {
  className?: string
}

export const CoinGif = (props: CoinGifProps) => {
  const { className } = props

  return (
    <div className={cl(cls.coin, className)}>
      <img src={'https://t-tolk.ru/img/coin.dada772b.gif'} alt="Gif" />
    </div>
  )
}
