import type { IAction } from '../model/action-types'

export const setIsMobile = (payload: boolean): IAction => ({
  type: 'IS_MOBILE',
  payload,
})
export const setIsMobileSmall = (payload: boolean): IAction => ({
  type: 'IS_MOBILE_SMALL',
  payload,
})
export const setIsMobileVerySmall = (payload: boolean): IAction => ({
  type: 'IS_MOBILE_VERY_SMALL',
  payload,
})
export const setIsAffirmationModal = (payload: boolean): IAction => ({
  type: 'IS_AFFIRMATION_MODAL',
  payload,
})
