import cl from 'classnames'
import { Button } from 'shared/ui/button'
import { CoinGif } from 'shared/ui'
import SilverCoinIcon from 'shared/assets/images/tariffs/SilverCoin.webp'
import GoldCoinIcon from 'shared/assets/images/tariffs/GoldCoin.webp'
import CheckIcon from 'shared/assets/icons/CheckIcon.webp'

import cls from './Tariffs.module.scss'
import useAppContext from 'app/store/useAppContext'
import classNames from 'classnames'

export const Tariffs = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  return (
    <section className={cls.section} id="tariffs">
      <div className={cls.content}>
        <CoinGif className={cls.coin} />

        <div className={cl('container__desktop', cls.container)}>
          <h2 className={cls.title}>Тарифы</h2>

          <div className={cls.cards}>
            <div className={classNames(cls.card, cls.card_mobile)}>
              <div className={cls.card_top}>
                <div className={cls.card_header}>
                  <img src={SilverCoinIcon} alt="SilverCoinIcon" />
                  <p>Silver</p>
                </div>

                <div className={cls.card_desc}>
                  <div className={cls.card_text}>
                    <img src={CheckIcon} alt="CheckIcon" />
                    <p>Доступ к просмотру теоретической части вебинара</p>
                  </div>
                  <div className={cls.card_text}>
                    <img src={CheckIcon} alt="CheckIcon" />
                    <p>
                      Дополнительные материалы к урокам: гайды, чек-листы,
                      саммари лекций
                    </p>
                  </div>
                  <div className={cls.card_text}>
                    <img src={CheckIcon} alt="CheckIcon" />
                    <p>Справочник с ответами на вопросы спикеру</p>
                  </div>
                  <div className={cls.card_text}>
                    <img src={CheckIcon} alt="CheckIcon" />
                    <p>Доступ к Telegram-каналу с полезной информацией</p>
                  </div>
                </div>
              </div>

              <div className={cls.card_bottom}>
                <Button className={cls.btn_accept}>Выбрать</Button>
                {!isMobileSmall && <p className={cls.card_price}>0 ₽</p>}
                {isMobileSmall && (
                  <div className={cls.mobile_price}>
                    <span className={cls.divider}></span>
                    <img src={SilverCoinIcon} alt="SilverCoinIcon" />
                    <p className={cls.card_price}>0 ₽</p>
                    <span className={cls.divider}></span>
                  </div>
                )}
              </div>
            </div>

            {!isMobileSmall && (
              <div className={cls.card}>
                <div className={cls.card_top}>
                  <div className={cls.card_header}>
                    <img src={GoldCoinIcon} alt="GoldCoinIcon" />
                    <p>Gold</p>
                  </div>

                  <div className={cls.card_desc}>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>Опции тарифа Silver</p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>Доступ к практическим занятиям и материалам</p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>Сертификат о прохождении вебинара</p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>
                        Закрытый чат для нетворкинга с другими участниками
                        вебинара
                      </p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>онлайн-практикум с экспертом Тинькофф</p>
                    </div>
                  </div>
                </div>

                <div className={cls.card_bottom}>
                  <Button className={cls.btn_accept}>Выбрать</Button>
                  {!isMobileSmall && <p className={cls.card_price}>490 ₽</p>}
                  {isMobileSmall && (
                    <div className={cls.mobile_price}>
                      <span className={cls.divider}></span>
                      <img src={GoldCoinIcon} alt="GoldCoinIcon" />
                      <p
                        className={classNames(
                          cls.card_price,
                          cls.card_price_full,
                        )}
                      >
                        490 ₽
                      </p>
                      <span className={cls.divider}></span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {isMobileSmall && (
            <div className={classNames(cls.cards, cls.cards_mobile)}>
              <div className={cls.card}>
                <div className={cls.card_top}>
                  <div className={cls.card_header}>
                    <img src={GoldCoinIcon} alt="GoldCoinIcon" />
                    <p>Gold</p>
                  </div>

                  <div className={cls.card_desc}>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>Опции тарифа Silver</p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>Доступ к практическим занятиям и материалам</p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>Сертификат о прохождении вебинара</p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>
                        Закрытый чат для нетворкинга с другими участниками
                        вебинара
                      </p>
                    </div>
                    <div className={cls.card_text}>
                      <img src={CheckIcon} alt="CheckIcon" />
                      <p>онлайн-практикум с экспертом Тинькофф</p>
                    </div>
                  </div>
                </div>

                <div className={cls.card_bottom}>
                  <Button className={cls.btn_accept}>Выбрать</Button>
                  {!isMobileSmall && <p className={cls.card_price}>490 ₽</p>}
                  {isMobileSmall && (
                    <div className={cls.mobile_price}>
                      <span className={cls.divider}></span>
                      <img src={GoldCoinIcon} alt="GoldCoinIcon" />
                      <p
                        className={classNames(
                          cls.card_price,
                          cls.card_price_full,
                        )}
                      >
                        490 ₽
                      </p>
                      <span className={cls.divider}></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
