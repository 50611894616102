import cls from './FirstProgram.module.scss'
import { useState } from 'react'
import useAppContext from 'app/store/useAppContext'
import classNames from 'classnames'

export const FirstProgram = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={cls.program}>
      <div className={cls.header}>
        <h3>Учимся вместе с командой Тинькофф</h3>
        <h4>Получите базовые знания и инструменты для формирования капитала</h4>
      </div>

      <div className={classNames(cls.table, isMobileSmall && cls.table_mobile)}>
        <div className={cls.content}>
          <div className={cls.left_block}>
            <p>Про деньги</p>
            <span>19:00–20:00</span>
          </div>
          <div className={cls.right_block}>
            <div className={cls.event}>
              <div className={cls.event_desc}>
                <p>Сколько стоит твоя мечта?</p>
              </div>
              <div className={cls.event_guid}>
                <p>Ирина Ахмадуллина</p>
                <p>Инвест-блогер, ведущая шоу «Деньги не спят»</p>
              </div>
            </div>
            <span className={cls.divider}></span>
            <div className={cls.event}>
              <div className={cls.event_desc}>
                <p>Колесо расходов: как не попасть в ловушку?</p>
              </div>
              <div className={cls.event_guid}>
                <p>Никита Юкович</p>
                <p>Тинькофф Журнал</p>
              </div>
            </div>
            <span className={cls.divider}></span>
            <div className={cls.event}>
              <div className={cls.event_desc}>
                <p>На чем заработать? 5 способов: от вкладов до бизнеса</p>
              </div>
              <div className={cls.event_guid}>
                <p>Ксения Братчикова</p>
                <p>Тинькофф</p>
              </div>
            </div>
            <span className={cls.divider}></span>
            <div className={cls.event}>
              <div className={cls.event_desc}>
                <p>Сила сложного процента: почему так просто?</p>
              </div>
              <div className={cls.event_guid}>
                <p>Руслан Мучипов</p>
                <p>Тинькофф Капитал</p>
              </div>
            </div>
          </div>
        </div>

        {!isMobileSmall && (
          <>
            <div className={cls.content}>
              <div className={cls.left_block}>
                <p>Финансовые мифы и реальность</p>
                <span>20:00–21:00</span>
              </div>
              <div className={cls.right_block}>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Сложно ли разобраться в инвестициях?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Мая Нелюбина</p>
                    <p>Инвест-блогер, ведущая шоу Private Talks</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Сколько времени на это нужно?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Денис Иванов</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Как вложить 100 рублей, чтобы заработать?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Артем Сухорученков</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Я не финансовый профи, мне можно?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Сергей Мальцев</p>
                    <p>Tinkoff Private</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Это надежно? Как работает финансовая система?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Иван Рыжиков</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={cls.content}>
              <div className={cls.left_block}>
                <p>Первые шаги в инвестициях</p>
                <span>21:00–22:00</span>
              </div>
              <div className={cls.right_block}>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>С чего начать: акции или облигации?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Анна Маркевич</p>
                    <p>Tinkoff Private</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Что купить и сколько? Создаем первый портфель</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Юрий Скоркин</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>
                      Как действовать дальше: от стратегии до ленивых инвестиций
                    </p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Андрей Иванов</p>
                    <p>Tinkoff Premium</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {isMobileSmall && isOpen && (
          <>
            <div className={cls.content}>
              <div className={cls.left_block}>
                <p>Финансовые мифы и реальность</p>
                <span>20:00–21:00</span>
              </div>
              <div className={cls.right_block}>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Сложно ли разобраться в инвестициях?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Мая Нелюбина</p>
                    <p>Инвест-блогер, ведущая шоу Private Talks</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Сколько времени на это нужно?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Денис Иванов</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Как вложить 100 рублей, чтобы заработать?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Артем Сухорученков</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Я не финансовый профи, мне можно?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Сергей Мальцев</p>
                    <p>Tinkoff Private</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Это надежно? Как работает финансовая система?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Иван Рыжиков</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={cls.content}>
              <div className={cls.left_block}>
                <p>Первые шаги в инвестициях</p>
                <span>21:00–22:00</span>
              </div>
              <div className={cls.right_block}>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>С чего начать: акции или облигации?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Анна Маркевич</p>
                    <p>Tinkoff Private</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Что купить и сколько? Создаем первый портфель</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Юрий Скоркин</p>
                    <p>Тинькофф Инвестиции</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>
                      Как действовать дальше: от стратегии до ленивых инвестиций
                    </p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Андрей Иванов</p>
                    <p>Tinkoff Premium</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {isMobileSmall && (
        <a
          href={!isOpen ? '#programs' : undefined}
          className={cls.open_program}
          onClick={onOpen}
        >
          {isOpen ? 'Скрыть программу' : 'Раскрыть всю программу'}
        </a>
      )}
    </div>
  )
}
