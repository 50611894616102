import cl from 'classnames'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import Layout from 'widgets/_layout'
import Menu from 'widgets/header/menu'
import { useHeaderAboveBlock } from 'shared/utils/hooks'
import { Logo } from 'shared/ui/logo'
import { Button } from 'shared/ui/button'
import { BurgerMenu } from './BurgetMenu/BurgerMenu'

import cls from './Header.module.scss'

export default function Header() {
  const { isMobile } = useIsMobileModal()

  const { isHeaderAboveBlock } = useHeaderAboveBlock('header', 'promo')

  return (
    <header className={cl(cls.header)} id="header">
      <Layout className={cls.layout}>
        <Logo />

        {!isMobile && (
          <div className={cls.header__right_block}>
            <Menu />
          </div>
        )}

        <div className={cls.header__right_block}>
          <Button
            variant={isHeaderAboveBlock ? 'variant4' : 'variant2'}
            isLink
            className={cls.btn_accept}
          >
            Принять участие
          </Button>

          {isMobile && <BurgerMenu />}
        </div>
      </Layout>
    </header>
  )
}
