import React, { ReactNode, useState } from 'react'

import cls from './Tabs.module.scss'

interface TabItem {
  title: string
  content: ReactNode
}

interface TabsProps {
  tabs: TabItem[]
}

export const Tabs = (props: TabsProps) => {
  const { tabs } = props

  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabClick = (index: number) => {
    setActiveTab(index)
  }

  return (
    <>
      <div className={cls.tabs}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={activeTab === index ? cls.active : ''}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className={cls.tab_content}>{tabs[activeTab].content}</div>
    </>
  )
}
