import cls from './FooterMenu.module.scss'

export const FooterMenu = () => {
  return (
    <nav className={cls.menu}>
      <ul>
        <li>
          <a href="#questions">О вебинаре</a>
        </li>
        <li>
          <a href="#programs">Программа</a>
        </li>
        <li>
          <a href="#speakers">Спикеры</a>
        </li>
        <li>
          <a href="#questions">FAQ</a>
        </li>
        <li>
          <a href="#tariffs">Принять участие</a>
        </li>
      </ul>
    </nav>
  )
}
