import React, { PropsWithChildren, useState } from 'react'
import { default as SliderLib, Settings } from 'react-slick'
import cl from 'classnames'
import SliderSwipeIcon from 'shared/assets/icons/SliderSwipeIcon.webp'

import './Slider.scss'

export function Slider(props: PropsWithChildren<Settings>) {
  const { children, dots = true } = props

  const settings = {
    dots,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const [isSwipe, setIsSwipe] = useState<boolean>(true)

  const onSwipe = () => {
    setIsSwipe(false)
  }

  return (
    <div className={cl('slider-container', 'slider-custom')}>
      <SliderLib {...props} {...settings} afterChange={onSwipe}>
        {children}
      </SliderLib>

      {isSwipe && (
        <div className="slider-swipe">
          <img src={SliderSwipeIcon} alt="SliderSwipeIcon" />
        </div>
      )}
    </div>
  )
}
