import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import {
  setIsMobile,
  setIsMobileSmall,
  setIsMobileVerySmall,
} from 'app/store/actions'
import { useIsMobileScreen } from 'shared/utils/hooks'

export default function useSetIsMobileModal() {
  const isMobile = useIsMobileScreen(768)
  const isMobileSmall = useIsMobileScreen(600)
  const isMobileVerySmall = useIsMobileScreen(500)
  const { dispatch } = useAppContext()

  useEffect(() => {
    dispatch(setIsMobile(isMobile))
    dispatch(setIsMobileSmall(isMobileSmall))
    dispatch(setIsMobileVerySmall(isMobileVerySmall))
  }, [isMobile, isMobileSmall, isMobileVerySmall])
}
