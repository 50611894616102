import React, { ButtonHTMLAttributes } from 'react'
import cl from 'classnames'

import cls from './Button.module.scss'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'variant2' | 'variant3' | 'variant4'
  className?: string
  href?: string
  isLink?: boolean
  isBlank?: boolean
}

export function Button(props: IButton) {
  const {
    variant = 'default',
    className,
    isLink,
    isBlank,
    href,
    ...rest
  } = props

  const classNameComponent = cl(cls.button, cls[variant], className)

  if (isLink) {
    return (
      <a
        href={href || '#tariffs'}
        target={isBlank ? '_blank' : undefined}
        // @ts-ignore
        onClick={props?.onClick}
        className={classNameComponent}
      >
        {props?.children}
      </a>
    )
  }

  return (
    <>
      <button {...rest} className={classNameComponent} />
    </>
  )
}
