import cl from 'classnames'
import { Slider } from 'shared/ui/slider'
import { speakersCardItems } from '../../model/consts/speakersCardItems'

import cls from './Cards.module.scss'

export default function Cards() {
  const cards = speakersCardItems.map((card) => {
    return (
      <li key={card.id} className={cl(cls.card)}>
        <div className={cls.card__img}>
          <img src={card.img} alt={`card-${card.id}`} />
        </div>
        <h3 className={cls.card__title}>{card.name}</h3>
        <div className={cls.card__desc}>
          <p>{card.desc}</p>
          <p>{card.text}</p>
        </div>
      </li>
    )
  })

  return (
    <>
      <Slider className={cls.cards_slider} dots={false}>
        {cards}
      </Slider>
    </>
  )
}
