import React from 'react'
import { Outlet } from 'react-router-dom'
import cl from 'classnames'

import Header from 'widgets/header'
import { Footer } from 'widgets/Footer'
import useSetIsMobileModal from 'features/modal/use-cases/useSetIsMobileModal'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'

export const MainLayout = () => {
  const { isMobileModal, isSomeModalsOpened } = useIsMobileModal()

  useSetIsMobileModal()

  return (
    <div className="app">
      <Header />
      <main
        className={cl(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}
