import { Reducer } from 'react'

import type { IAppStore } from '../model/app-store'
import type { IAction } from '../model/action-types'

export const reducer: Reducer<IAppStore, IAction> = (state, action) => {
  const payload = action.payload
  const type = action.type

  switch (type) {
    case 'IS_MOBILE':
      return { ...state, isMobile: payload }
    case 'IS_MOBILE_SMALL':
      return { ...state, isMobileSmall: payload }
    case 'IS_MOBILE_VERY_SMALL':
      return { ...state, isMobileVerySmall: payload }
    case 'IS_AFFIRMATION_MODAL':
      return {
        ...state,
        modals: { ...state.modals, isAffirmationModal: payload },
      }
    case 'IS_SUCCESS_AFFIRMATION_MODAL':
      return {
        ...state,
        modals: { ...state.modals, isSuccessAffirmationModal: payload },
      }
    default:
      return state
  }
}
