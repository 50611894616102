import cl from 'classnames'
import { Promotion } from './Promotion'
import { Features } from './Features'
import { Programs } from './Programs'
import { Speakers } from './Speakers'
import { Tariffs } from './Tariffs'
import { Questions } from './questions'

import cls from './Home.module.scss'

export default function Home() {
  return (
    <div className={cl(cls.home)}>
      <Promotion />
      <Features />
      <Programs />
      <Speakers />
      <Tariffs />
      <Questions />
    </div>
  )
}
