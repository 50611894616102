import cl from 'classnames'
import useAppContext from 'app/store/useAppContext'
import TalkDeskImg from 'shared/assets/images/promo/TalkDesk.webp'
import TalkMobileImg from 'shared/assets/images/promo/TalkMobile.webp'
import { Button } from 'shared/ui/button'

import cls from './Promotion.module.scss'

export const Promotion = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  return (
    <section className={cl('flex__col_center', cls.section)} id="promo">
      <div className={cl('container__desktop', cls.container)}>
        <div className={cls.block_container}>
          <div className={cls.talk}>
            {!isMobileSmall && <img src={TalkDeskImg} alt="TalkDeskImg" />}
            {isMobileSmall && <img src={TalkMobileImg} alt="TalkMobileImg" />}
          </div>
        </div>

        <div className={cls.block_container}>
          <h1 className={cls.title}>
            <p>Быстрый старт в финансовое благополучие с экспертами Тинькофф</p>
          </h1>
        </div>
        <div className={cls.block_container}>
          <div className={cls.desc}>
            <Button isLink className={cls.btn_accept}>
              Принять участие
            </Button>

            <a
              href="#add_to_calendar"
              target="_blank"
              className={cls.btn_calendar}
            >
              Добавить в календарь
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
