export const isScreenWidth = (width: number) => {
  return window.innerWidth < width
}

export function someObjValuesTrue(obj: any) {
  for (let key in obj) {
    if (obj[key]) {
      return true
    }
  }
  return false
}
