import { useState } from 'react'
import cl from 'classnames'
import useAppContext from 'app/store/useAppContext'
import { Button } from 'shared/ui/button'
import { speakersCardItems } from '../model/consts/speakersCardItems'
import Cards from './Cards/Cards'

import cls from './Speakers.module.scss'

export const Speakers = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  const [showAllSpeakers, setShowAllSpeakers] = useState(false)
  const [visibleSpeakers, setVisibleSpeakers] = useState(
    speakersCardItems.slice(0, 5),
  )

  const handleShowAllSpeakers = () => {
    setShowAllSpeakers(!showAllSpeakers)
    setVisibleSpeakers(
      showAllSpeakers ? speakersCardItems.slice(0, 5) : speakersCardItems,
    )
  }

  return (
    <section className={cls.section} id="speakers">
      <div className={cls.content}>
        <div className={cl('container__desktop', cls.container)}>
          <h2 className={cls.title}>Спикеры</h2>

          {isMobileSmall && <Cards />}

          {!isMobileSmall && (
            <>
              <div className={cls.cards}>
                {visibleSpeakers.map((card) => (
                  <div key={card.id} className={cls.card}>
                    <img
                      className={cls.card_img}
                      src={card.img}
                      alt={`feature-icon${card.id}`}
                    />
                    <p className={cls.card_name}>{card.name}</p>
                    <p className={cls.card_desc}>{card.desc}</p>
                    <p className={cls.card_text}>{card.text}</p>
                  </div>
                ))}
              </div>

              <Button
                variant="variant3"
                isLink={showAllSpeakers}
                href="#speakers"
                onClick={handleShowAllSpeakers}
              >
                {showAllSpeakers ? 'Скрыть спикеров' : 'Показать всех спикеров'}
              </Button>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
