import styles from './Menu.module.scss'

export default function Menu() {
  return (
    <nav className={styles.menu}>
      <ul>
        <li>
          <a href="#programs">Программа</a>
        </li>
        <li>
          <a href="#speakers">Спикеры</a>
        </li>
        <li>
          <a href="#questions">FAQ</a>
        </li>
      </ul>
    </nav>
  )
}
