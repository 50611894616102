import { PropsWithChildren } from 'react'
import cl from 'classnames'

import cls from './Layout.module.scss'

interface ILayoutProps extends PropsWithChildren {
  className?: string
  containerClassName?: string
}

export default function Layout(props: ILayoutProps) {
  const { children, className, containerClassName } = props

  return (
    <div className={`${cls.layout} ${className ?? ''}`}>
      <div
        className={cl(
          'container__desktop',
          'flex__row_center',
          cls.container,
          containerClassName,
        )}
      >
        {children}
      </div>
    </div>
  )
}
