import cls from './SecondProgram.module.scss'
import classNames from 'classnames'
import useAppContext from 'app/store/useAppContext'
import { useState } from 'react'

export const SecondProgram = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={cls.program}>
      <div className={cls.header}>
        <h3>Практикуемся и ловим инсайты от экспертов</h3>
        <h4>
          Закрепите пройденную теорию на практике и обсудите с экспертами
          финансового рынка текущую экономическую ситуацию
        </h4>
      </div>

      <div className={classNames(cls.table, isMobileSmall && cls.table_mobile)}>
        <div className={cls.content}>
          <div className={cls.left_block}>
            <p>Про стратегию</p>
            <span>19:00–19:30</span>
          </div>
          <div className={cls.right_block}>
            <div className={cls.event}>
              <div className={cls.event_desc}>
                <p>
                  Определяем свой риск-профиль и выбираем стратегию
                  инвестирования
                </p>
              </div>
              <div className={cls.event_guid}>
                <p>Анна Маркевич</p>
                <p>Тинькофф</p>
              </div>
            </div>
          </div>
        </div>

        {!isMobileSmall && (
          <>
            <div className={cls.content}>
              <div className={cls.left_block}>
                <p>От составления личного бюджета до инвестиций</p>
                <span>19:30–21:00</span>
              </div>
              <div className={cls.right_block}>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Как выбрать акцию?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Богдан Павличенко</p>
                    <p>Академия инвестиций Тинькофф</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Как составить свой первый портфель?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Ирина Ахмадуллина</p>
                    <p>Инвест-блогер, ведущая шоу «Деньги не спят»</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Онлайн-практикум и сессия вопросов-ответов</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Ирина Ахмадуллина</p>
                    <p>Инвест-блогер, ведущая шоу «Деньги не спят»</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {isMobileSmall && isOpen && (
          <>
            <div className={cls.content}>
              <div className={cls.left_block}>
                <p>От составления личного бюджета до инвестиций</p>
                <span>19:30–21:00</span>
              </div>
              <div className={cls.right_block}>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Как выбрать акцию?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Богдан Павличенко</p>
                    <p>Академия инвестиций Тинькофф</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Как составить свой первый портфель?</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Ирина Ахмадуллина</p>
                    <p>Инвест-блогер, ведущая шоу «Деньги не спят»</p>
                  </div>
                </div>
                <span className={cls.divider}></span>
                <div className={cls.event}>
                  <div className={cls.event_desc}>
                    <p>Онлайн-практикум и сессия вопросов-ответов</p>
                  </div>
                  <div className={cls.event_guid}>
                    <p>Ирина Ахмадуллина</p>
                    <p>Инвест-блогер, ведущая шоу «Деньги не спят»</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {isMobileSmall && (
        <a
          href={!isOpen ? '#programs' : undefined}
          className={cls.open_program}
          onClick={onOpen}
        >
          {isOpen ? 'Скрыть программу' : 'Раскрыть всю программу'}
        </a>
      )}
    </div>
  )
}
