import { useEffect, useState } from 'react'

import { isScreenWidth } from 'shared/utils/functions'

export const useIsMobileScreen = (width: number) => {
  const initialScreenWidth = isScreenWidth(width)
  const [isMobile, setIsMobile] = useState<boolean>(initialScreenWidth || false)

  const handleResize = () => {
    if (isScreenWidth(width)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

export const useHeaderAboveBlock = (headerId: string, blockId: string) => {
  const [isHeaderAboveBlock, setIsHeaderAboveBlock] = useState(false)

  useEffect(() => {
    const header = document.getElementById(headerId)
    const block = document.getElementById(blockId)

    if (!header || !block) {
      console.error('Header or block element not found')
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderAboveBlock(!entry.isIntersecting)
      },
      {
        root: null,
        threshold: 0,
        rootMargin: `-${header.offsetHeight}px 0px 0px 0px`,
      },
    )

    observer.observe(block)

    return () => {
      observer.disconnect()
    }
  }, [headerId, blockId])

  return {
    isHeaderAboveBlock,
  }
}
