import type { IAppStore } from './app-store'

export const initialState: IAppStore = {
  isMobile: false,
  isMobileSmall: false,
  isMobileVerySmall: false,
  modals: {
    isAffirmationModal: false,
    isSuccessAffirmationModal: false,
  },
}
