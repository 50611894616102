import YouTube from 'react-youtube'
import cl from 'classnames'
import useAppContext from 'app/store/useAppContext'
import { Button } from 'shared/ui/button'
import { featuresCardItems } from '../model/consts/featuresCardItems'

import cls from './Features.module.scss'

export const Features = () => {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  const opts = {
    height: '630',
    width: '100%',
    borderRadius: '24px',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  }

  const _onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }

  return (
    <section className={cls.section} id="features">
      <div className={cls.content}>
        <div className={cl('container__desktop', cls.container)}>
          <h2 className={cls.title}>«Толк старт» для вас, если вы хотите</h2>
          <div className={cls.cards}>
            {featuresCardItems.map((card) => {
              return (
                <div key={card.id} className={cls.card}>
                  <img
                    className={cls.card_img}
                    src={card.img}
                    alt={`feature-icon${card.id}`}
                  />
                  <p className={cls.card_desc}>{card.text}</p>
                </div>
              )
            })}
          </div>
          <Button isLink className={cls.btn_accept}>
            Принять участие
          </Button>
        </div>

        <div
          className={cl(
            'container__desktop',
            cls.container,
            isMobileSmall && cls.container_mobile,
          )}
        >
          <h2 className={cls.title}>О чем «Толк старт»?</h2>
          <div className={cls.video}>
            <YouTube videoId="VRTGsC6VsKo" opts={opts} onReady={_onReady} />
          </div>
        </div>
      </div>
    </section>
  )
}
