import Layout from 'widgets/_layout'
import { Logo } from 'shared/ui/logo'
import { FooterMenu } from './FooterMenu/FooterMenu'

import cls from './Footer.module.scss'

export const Footer = () => {
  return (
    <footer className={cls.footer}>
      <Layout className={cls.layout} containerClassName={cls.container}>
        <Logo type="dark" />
        <FooterMenu />
        <div>
          <p>
            Gold — Голд, Silver — Сильвер, Гайд — инструкция, Саммари — краткое
            содержание
          </p>
        </div>
        <span className={cls.divider}></span>
        <div className={cls.info_block}>
          <div>
            <p>
              © 2006—2023, АО «Тинькофф Банк», универсальная лицензия ЦБ РФ №
              2673. Официальный сайт – www.tinkoff.ru
            </p>
          </div>
          <div className={cls.info_block__right}>
            <a href="#privacy_policy">Политика конфиденциальности</a>
            <a href="#offer">Оферта</a>
          </div>
        </div>
      </Layout>
    </footer>
  )
}
