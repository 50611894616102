import SpeakerImg1 from 'shared/assets/images/speakers/Speaker1.webp'
import SpeakerImg2 from 'shared/assets/images/speakers/Speaker2.webp'
import SpeakerImg3 from 'shared/assets/images/speakers/Speaker3.webp'
import SpeakerImg4 from 'shared/assets/images/speakers/Speaker4.webp'
import SpeakerImg5 from 'shared/assets/images/speakers/Speaker5.webp'
import SpeakerImg6 from 'shared/assets/images/speakers/Speaker6.webp'
import SpeakerImg7 from 'shared/assets/images/speakers/Speaker7.webp'
import SpeakerImg8 from 'shared/assets/images/speakers/Speaker8.webp'
import SpeakerImg9 from 'shared/assets/images/speakers/Speaker9.webp'
import SpeakerImg10 from 'shared/assets/images/speakers/Speaker10.webp'
import SpeakerImg11 from 'shared/assets/images/speakers/Speaker11.webp'
import SpeakerImg12 from 'shared/assets/images/speakers/Speaker12.webp'
import SpeakerImg13 from 'shared/assets/images/speakers/Speaker13.webp'

interface SpeakersCardItem {
  id: number
  img: string
  name: string
  desc: string
  text: string
}

export const speakersCardItems: SpeakersCardItem[] = [
  {
    id: 1,
    img: SpeakerImg1,
    name: 'Ирина Ахмадуллина',
    desc: 'Инвест-блогер, ведущая шоу «Деньги не спят»',
    text: 'Самый молодой кандидат экономических наук в своем ВУЗе, опытный инвестор. Инвестирует по жизни в широком смысле.',
  },
  {
    id: 2,
    img: SpeakerImg2,
    name: 'Никита Юкович',
    desc: 'Тинькофф Журнал',
    text: 'Главный редактор одного из лучших медиа про деньги и жизнь. Активно делится личным опытом и знаниями о финансах.',
  },
  {
    id: 3,
    img: SpeakerImg3,
    name: 'Ксения Братчикова',
    desc: 'Тинькофф',
    text: 'Создает образовательные проекты по финансовой грамотности с 2007 года. Инвестирует более 6 лет в различные активы.',
  },
  {
    id: 4,
    img: SpeakerImg4,
    name: 'Руслан Мучипов',
    desc: 'Тинькофф Капитал',
    text: 'Создал и запустил биржевые фонды Тинькофф. Вот уже почти 20 лет знает в инвестициях толк.',
  },
  {
    id: 5,
    img: SpeakerImg5,
    name: 'Мая Нелюбина',
    desc: 'Инвест-блогер, ведущая шоу Private Talks',
    text: 'Мастер общения с экспертами финансового рынка. Осмотрительно инвестирует уже 5 лет.',
  },
  {
    id: 6,
    img: SpeakerImg6,
    name: 'Денис Иванов',
    desc: 'Тинькофф Инвестиции',
    text: 'Квалифицированный инвестор, развивает Инвестиции для трейдеров. Сделал трейдинг и инвестиции лайфстайлом.',
  },
  {
    id: 7,
    img: SpeakerImg7,
    name: 'Артем Сухорученков',
    desc: 'Тинькофф Инвестиции',
    text: 'Делает так, чтобы о преимуществах инвестиций знало как можно больше людей. Инвестирует более 10 лет в различные активы.',
  },
  {
    id: 8,
    img: SpeakerImg8,
    name: 'Сергей Мальцев',
    desc: 'Tinkoff Private',
    text: 'В Tinkoff Private делает клиентов богаче. 18 лет применяет академические знания на практике.',
  },
  {
    id: 9,
    img: SpeakerImg9,
    name: 'Иван Рыжиков',
    desc: 'Тинькофф Инвестиции',
    text: 'Входит в команду создателей Тинькофф Инвестиции. Знает, как все устроено и делает так, чтобы все работало.',
  },
  {
    id: 10,
    img: SpeakerImg10,
    name: 'Анна Маркевич',
    desc: 'Tinkoff Private',
    text: 'Развивает все инвестиционные продукты и персональное консультирование. Дотошный теоретик с красным дипломом.',
  },
  {
    id: 11,
    img: SpeakerImg11,
    name: 'Юрий Скоркин',
    desc: 'Тинькофф Инвестиции',
    text: 'Знает все о пассивных инвестициях и помогает достигать финансовых целей. Заработал на квартиру на фондовом рынке.',
  },
  {
    id: 12,
    img: SpeakerImg12,
    name: 'Андрей Иванов',
    desc: 'Tinkoff Premium',
    text: 'Помогает клиентам инвестировать грамотно. Знает, как сформировать долгосрочный прибыльный инвестиционный портфель.',
  },
  {
    id: 13,
    img: SpeakerImg13,
    name: 'Богдан Павличенко',
    desc: 'Академия инвестиций Тинькофф',
    text: 'Главный редактор Академии, квалифицированный инвестор, доходность портфеля опережает индекс Мосбиржи.',
  },
]
